@import "./Colors.scss";

$media: screen;
$widescreen: 1900px;
$desktop: 1500px;
$desktopSmall: 1000px;
$tablet: 750px;
$mobile: 500px;

@mixin responsive($breakpoint) {
  @if $breakpoint == mobile {
    @media #{$media} and (max-width: $mobile) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media #{$media} and (max-width: $tablet) {
      @content;
    }
  } @else if $breakpoint == desktopSmall {
    @media #{$media} and (max-width: $desktopSmall) {
      @content;
    }
  } @else if $breakpoint == desktopSmallLarger {
    @media #{$media} and (min-width: $desktopSmall) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media #{$media} and (min-width: $tablet) {
      @content;
    }
  } @else if $breakpoint == widescreen {
    @media #{$media} and (min-width: $desktop) {
      @content;
    }
  } @else if $breakpoint == widescreenSmaller {
    @media #{$media} and (max-width: $desktop) {
      @content;
    }
  } @else if $breakpoint == ultrawide {
    @media #{$media} and (min-width: $widescreen) {
      @content;
    }
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar-track {
    background-color: black;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar {
    width: 10px;

    @include responsive(tablet) {
      width: 6px;
    }

    @include responsive(mobile) {
      width: 4px;
    }
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 5px;
    background-position: center;
    background-size: 100% 100%;
    background-size: 6.25rem 6.25rem;
    background-color: #303030;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    @include responsive(tablet) {
      width: 6px;
    }

    @include responsive(mobile) {
      width: 4px;
    }
  }
}

@mixin linear-gradient($direction, $colors) {
  background: nth($colors, 1);
  background: -webkit-linear-gradient($direction, $colors);
  background: linear-gradient($direction, $colors);
}

@mixin shadow($color: $backgroundTile, $size: 0px, $blur: 5px) {
  text-shadow: -$size (-$size) $blur $color, $size (-$size) $blur $color,
    -$size $size $blur $color, $size $size $blur $color;
}

@function hover-shadow(
  $shadow-color: #000000,
  $hover-distance: 5px,
  $opacity: 0.2
) {
  $blur: $hover-distance * 1.5;
  $spread: $hover-distance * 0.2;
  $x-offset: 0;
  $y-offset: $hover-distance;
  $adjusted-shadow-color: transparentize($shadow-color, 1 - $opacity);

  @return $x-offset, $y-offset, $blur, $spread, $adjusted-shadow-color;
}

@mixin card {
  padding: 3rem;
  border-radius: 3rem;
  background-color: $backgroundPaper;
  box-shadow: 0 0 3rem 0px black;

  @include responsive(desktop) {
    padding: 4rem;
  }
}

// slanty floating shadow card angled like the star wars intro
%star-wars-perspective {
  border: 1px solid;
  border-radius: 1rem;
  box-shadow: #161f27 0px 4.25rem 123px -25px, #131a20 0px 35px 75px -35px;
  transform: perspective(5em) rotateX(15deg);
  border-color: #d5dce2 #d5dce2 #b8c2cc;
}

// Mixin for parent container and child elements perspective
@mixin perspective-container($depth-difference: 2rem) {
  position: relative;
  perspective: 1000px;

  > * {
    @for $i from 1 through 100 {
      &:nth-child(#{$i}) {
        position: absolute;
        transform: translateZ(($depth-difference * $i));
        z-index: 100 - $i;
      }
    }
  }
}

%hide-scrollbar {
  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

%no-padding {
  padding: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%inline-flex-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

%default-text-props {
  margin: 0;
  color: white;
}

%h1 {
  padding-bottom: 2rem;
  font-family: "Inter";
  font-size: 5rem;
  font-weight: 600;
  line-height: 6rem;
  @extend %default-text-props;
}

%h2 {
  padding-bottom: 2rem;
  font-family: "Inter";
  font-size: 4rem;
  font-weight: 600;
  line-height: 5rem;
  @extend %default-text-props;
}

%h3 {
  padding-bottom: 2rem;
  font-family: "Inter";
  font-size: 3rem;
  font-weight: 600;
  line-height: 4rem;
  @extend %default-text-props;
}

%h4 {
  padding-bottom: 1rem;
  font-family: "Inter";
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3.25rem;
  @extend %default-text-props;
}

%h5 {
  padding-bottom: 1rem;
  font-family: "Inter";
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.25rem;
  @extend %default-text-props;
}

%h6 {
  font-family: "Inter";
  font-size: 1.25rem;
  font-weight: 600;
  @extend %default-text-props;
}

%p {
  padding: 1rem 0;
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 400;
  @extend %default-text-props;
}

%li {
  padding-bottom: 1rem;
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 400;
  @extend %default-text-props;
}
