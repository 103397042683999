@import "../../Styles/master";

.calendly-button {
  all: unset;
  height: auto !important;
  padding: 0.5rem 2rem;
  border-radius: 3rem;
  transition: all 0.3s ease-in-out;
  opacity: 0.8 !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  @extend %flex-center;
  @extend %h5;

  &:hover {
    box-shadow: 0px 0px 10px 0px $tealFrost;
    opacity: 1 !important;
  }

  &.calendly-sleek {
    background-color: $backgroundPaperButton;
    color: white;
  }

  &:not(.calendly-sleek) {
    background-color: $purple;
    color: white;
  }
}
