$background: #0e0e11;
$backgroundTile: #18181c;
$backgroundPaper: #1b2328;
$backgroundPaperButton: #2e3a42;
$cultured: #fafafa;
$errorRed: #fd6969;
$green: #3ad96b;
$purple: #574ae2;
$teal: #00ffd9;
$yellow: #fff480;
$uiBackground: #282a36;
$uiLight: #515260;
$uiLighter: #616270;
$uiLightText: #7d7e8d;
$uiSuperLightText: #bcbdcd;
$invoiceGreen: #60bc57;
$invoiceYellow: #fdb10c;
$gold: #d4af37;
$invoiceRed: #ee5951;
$invoiceSent: #c1e4fe;
$spaceCadet: #141225;

// New Colors
$darkFrost: #4163ae;
$blueFrost: #30a8df;
$tealFrost: #42c4df;
$lightFrost: #65c9cf;
$blueGrey: #6d8ea2;

$greenShadow: 0 0 0 0.15em #3ac97b4d;
$grayShadow: 0 4px 1.5rem 0 #00000040;

$grayBorder: 2px solid #e0e0e0;

$BAMGradient: linear-gradient(90deg, $teal 20%, $green 80%);
