@import url(sanitize.css);
@import "master";

h1 {
  @extend %h1;
}

h2 {
  @extend %h2;
}

h3 {
  @extend %h3;
}

h4 {
  @extend %h4;
}

h5 {
  @extend %h5;
}

h6 {
  @extend %h6;
}

p {
  @extend %p;
}

li {
  @extend %li;
}

body,
html,
#firstborn {
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: clip;
  background-color: #060610;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  // possible iOS bug fix
  position: relative;
}

body {
  /* iOS viewport bug fix */
  height: 100vh;
  min-height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  margin: 0;
  font-family: -apple-system, "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @extend %hide-scrollbar;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  /* iOS viewport bug fix */
  height: -webkit-fill-available;
  font-size: 12px;
  -webkit-text-size-adjust: none;
  scroll-behavior: smooth;

  @include responsive(desktopSmallLarger) {
    font-size: min(14px);
  }

  @include responsive(tablet) {
    font-size: 12px;
  }

  @include responsive(mobile) {
    font-size: 12px;
  }
}

.calendly-popup {
  max-height: 100vh !important;
}

.italic {
  font-style: italic;
}

.green {
  color: $green;
}

.purple {
  color: $purple;
}

.teal {
  color: $teal;
}

.blueFrost {
  color: $blueFrost;
}

.errorRed {
  color: $errorRed;
}

.yellow {
  color: $yellow;
}
